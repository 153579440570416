import React, { useEffect} from "react";
import './App.css';
import {ThreeDots} from "react-loader-spinner";
import {useCookies} from "react-cookie";

const RedirectUri = () => {
    const [cookie, setCookie, remove] = useCookies(['client_id', 'scope', 'response_type', 'redirect_uri', 'state']);
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code')

    useEffect(() => {
        if(code && cookie.state && cookie.redirect_uri){
            window.location.href = cookie.redirect_uri + "?code="+ code + "&state=" + cookie.state
        }
    }, [code])

    return(
        <div className="App">
            <header className="App-header">
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#0170ce"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    visible={true}
                />
            </header>
        </div>
    )
}

export default RedirectUri;
