import React, {useEffect, useState} from 'react';
import './App.css';
import {ThreeDots} from "react-loader-spinner";
import {useCookies} from "react-cookie";


export interface DomainsSpec {
    name: string
}
export interface GenericStrapiSing<T> {
    data: { id: number, attributes:  T }
}
export interface GenericStrapiPlu<T> {
    data: {  id: number, attributes:  T }[]
}


const App = () => {
    const [cookie, setCookie, remove] = useCookies(['client_id', 'scope', 'response_type', 'redirect_uri', 'state']);
    const urlParams = new URLSearchParams(window.location.search);
    const urlParamsFromHref = new URLSearchParams(window.location.href);
    const client_id = urlParams.get('client_id')
    const scope = urlParams.get('scope')
    const response_type = urlParams.get('response_type')
    const redirect_uri = urlParamsFromHref.get('redirect_uri')
    const state = urlParams.get('state')
    const [unauthorized, setUnauthorized] = useState<boolean>(false);


    const checkIfDomainExist =  async (domain : string | undefined) => {
        if(domain){
            let url : any = process.env.REACT_APP_BO + `?filters[name][$eq]=${domain}`
            fetch(url)
                .then(res => res.json())
                .then(
                    (result : GenericStrapiPlu<DomainsSpec>) => {
                        if(result.data.length >= 1){
                            return true
                        }else return false
                    },
                    (error) => {
                        console.log('error', error)
                        return false
                    }
                )
        } else {
            return false
        }

    }

    const extractHostname = (urlString : string) => {
        try {
          let url = new URL(urlString);
          let hostnameWithPort = url.hostname + (url.port ? `:${url.port}` : '');
          return hostnameWithPort;
        } catch (error) {
          console.error('Invalid URL');
        }
    }
    const checkDesktop = (urlString : string) => {
        return urlString.startsWith("file")
    }
    useEffect(() => {
        console.log(extractHostname(redirect_uri??""))
        if(  !client_id || !scope || !response_type || !redirect_uri || !state || (!checkDesktop(redirect_uri) && !checkIfDomainExist(extractHostname(redirect_uri)))){
            setUnauthorized(true)
        }
        else{
            setCookie('client_id', client_id)
            setCookie('scope', scope)
            setCookie('response_type', response_type)
            setCookie('redirect_uri', redirect_uri)
            setCookie('state', state)

            const asyncCall = async () => {
                const url : any = process.env.REACT_APP_BACK_END
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'appName': client_id
                    }
                });
                if(response.status === 200){
                    const elementReceive : any = await response.json()

                    window.location.href = elementReceive.authorizationUri
                }
            };
            asyncCall();
        }
    }, [client_id, scope, response_type, redirect_uri,state])

  return (
      <>
          {!unauthorized ? (
              <div className="App">
                  <header className="App-header">
                      <ThreeDots
                          height="80"
                          width="80"
                          radius="9"
                          color="#0170ce"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          visible={true}
                      />
                  </header>
              </div>
          ) : (
              <div className="error_contaire">
                  <div className="container">
                      <h1>403</h1>
                      <p>Access Forbidden</p>
                  </div>
              </div>

          )}

      </>

  );
}

export default App;
